import { ReactElement } from 'react';

type LocationCardProps = {
  onClick: () => void;
  isOpen?: boolean;
  isLargeScreen?: boolean;
  header: string;
  className?: string;
};

export default function AccordianBanner({
  onClick,
  isOpen,
  isLargeScreen = false,
  header,
  className = '',
}: LocationCardProps): ReactElement {
  return (
    <div
      role="button"
      className={`flex items-center justify-between lg:justify-center bg-muted lg:bg-transparent border-secondary lg:border-none p-4 lg:p-0 lg:pb-4 border-t-2 border-b-2 cursor-pointer ${className}`}
      onClick={onClick}
      tabIndex={0}
    >
      <div className="text-2xl lg:text-4xl py-4 lg:py-0 text-primary-actual">{header}</div>
      {isLargeScreen ? null : (
        <div className="h-calloutIcon flex items-center justify-center">
          <svg
            className={`transform text-highlight transition-transform duration-300 w-full h-full ${
              isOpen === true ? 'rotate-180' : isOpen === false ? 'rotate-0' : '-rotate-90'
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#3cc8d7"
            strokeWidth="2"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        </div>
      )}
    </div>
  );
}
