import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Map as MapLibreMap,
  Marker,
  LngLatBounds,
  NavigationControl,
  FullscreenControl,
  Popup,
} from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import LocationMarkerSvg from './MapMarker/LocationMarkerSvg';
import { UserLocation } from '../../pages/locations/index';
import UserMarkerSvg from './MapMarker/UserMarkerSvg';
import ReactDOMServer from 'react-dom/server';
import { Location } from '../../pages/locations/index';
import InfoCard from './InfoCard';
import NewMapCard from './NewMapCard';

export interface IconDetails {
  title?: string;
  hideEyeOnPin: boolean;
  markerIcon: string;
  classNames?: string;
  focused?: boolean;
}

interface MapOption {
  fullscreenControl?: boolean;
  zoomControl?: boolean;
  unBlockOnClick?: boolean;
}
interface MapProps {
  userLocation?: UserLocation | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  foundLocations?: Location[];
  activeLocation?: Location | null;
  showPopUp?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActiveLocation?: any;
  defaultCenter?: { lat: number; lng: number };
  iconDetails?: IconDetails;
  mapOptions?: MapOption;
  renderInfoCard?: string;
  onMapLoaded?(map: MapLibreMap): void;
  zoom?: number;
}

export default function NewMap({
  userLocation,
  foundLocations,
  activeLocation,
  setActiveLocation,
  showPopUp,
  defaultCenter,
  iconDetails,
  renderInfoCard,
  zoom = 4,
  mapOptions = { fullscreenControl: true, zoomControl: true, unBlockOnClick: true },
  ...props
}: MapProps): React.ReactElement {
  const [size, setSize] = useState<string>('lg');

  useEffect(() => {
    const updateScreenSize = (): void => {
      const width = window.innerWidth;

      if (width >= 1024) {
        setSize('lg');
      } else {
        setSize('sm');
      }
    };
    updateScreenSize();
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapLibreMap | null>(null);
  const currentPopupRef = useRef<Popup | null>(null);

  function handleMarkerClick(loc: Location): void {
    setActiveLocation(activeLocation !== loc ? loc : null);
    mapRef.current?.easeTo({
      center: [loc.map.lon, loc.map.lat],
      offset: [0, -mapContainerRef.current!.clientHeight / 3],
      duration: 1000,
    });
  }

  const initializeMap = (): void => {
    if (mapContainerRef.current) {
      const map = new MapLibreMap({
        container: mapContainerRef.current,
        style: `https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/LocationServiceMap/style-descriptor?key=${process.env.NEXT_PUBLIC_AWS_LOCATION}`,
        center: defaultCenter ? [defaultCenter.lng, defaultCenter.lat] : [0, 0],
        zoom: zoom,
      });

      mapRef.current = map;

      const navControl = new NavigationControl({
        showCompass: false,
        showZoom: true,
      });

      if (mapOptions.zoomControl) mapRef.current.addControl(navControl, 'bottom-right');

      mapRef.current.scrollZoom.enable();
      mapRef.current.touchZoomRotate.enable();
      mapRef.current.touchPitch.enable();
      mapRef.current.dragPan.enable();

      mapRef.current.getCanvas().style.cursor = 'grab';

      if (mapOptions?.fullscreenControl) {
        const fullscreenControl = new FullscreenControl();
        mapRef.current.addControl(fullscreenControl, 'top-right');
      }
      if (props.onMapLoaded) props.onMapLoaded(map);

      map.on('load', () => {
        setMapBounds();
        addMarkers();
      });
    }
  };

  useEffect(() => {
    if (activeLocation) {
      mapRef.current?.easeTo({
        center: [activeLocation.map.lon, activeLocation.map.lat],
        offset: [0, -mapContainerRef.current!.clientHeight / 3],
        duration: 1000,
      });
    }
  }, [activeLocation]);

  const addMarkers = (): void => {
    // Remove existing markers
    const existingMarkers = document.querySelectorAll('.marker');

    if (existingMarkers) {
      existingMarkers.forEach((marker) => marker.remove());
    }

    if (userLocation?.map?.lng && userLocation?.map?.lat) {
      const userLocationMarkerElement = document.createElement('div');
      userLocationMarkerElement.className = 'marker';

      // Render the user location marker component
      ReactDOM.render(
        <UserMarkerSvg classNames="text-secondary-actual" />,
        userLocationMarkerElement
      );

      userLocationMarkerElement.style.width = '40px';
      userLocationMarkerElement.style.height = '40px';

      // Add marker to map
      new Marker({ element: userLocationMarkerElement })
        .setLngLat([userLocation.map.lng, userLocation.map.lat])
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .addTo(mapRef.current!);
    }

    (foundLocations || []).forEach((loc) => {
      if (document.querySelector(`[data-slug="${loc.slug}"]`)) return;

      const locationMarkerElement = document.createElement('div');
      locationMarkerElement.className = 'marker';
      locationMarkerElement.dataset.slug = loc.slug;

      locationMarkerElement.style.zIndex =
        JSON.stringify(activeLocation) === JSON.stringify(loc) ? '20' : '1';

      // Render the custom marker component
      ReactDOM.render(
        <LocationMarkerSvg
          title={iconDetails?.title || `Location: ${loc.name}`}
          hideEyeOnPin={iconDetails?.hideEyeOnPin || false}
          markerIcon={iconDetails?.markerIcon || 'Eye-V01'}
          focused={activeLocation?.slug === loc.slug}
          classNames={iconDetails?.classNames}
        />,
        locationMarkerElement
      );

      locationMarkerElement.style.width = '40px';
      locationMarkerElement.style.height = '40px';

      new Marker({ element: locationMarkerElement })
        .setLngLat([loc.map.lon, loc.map.lat])
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .addTo(mapRef.current!);

      if (mapOptions?.unBlockOnClick) {
        locationMarkerElement.addEventListener('click', () => handleMarkerClick(loc));
        // locationMarkerElement.addEventListener('touchstart', () => handleMarkerClick(loc));
      }
    });
  };

  const setMapBounds = (): void => {
    if (!mapRef.current || (foundLocations || []).length === 0) return;

    const bounds = new LngLatBounds();

    (foundLocations || []).forEach((loc) => {
      bounds.extend([loc.map.lon, loc.map.lat]);
    });

    if (userLocation) {
      bounds.extend([userLocation.map.lng, userLocation.map.lat]);
    }

    mapRef.current.fitBounds(bounds, {
      padding: 100,
      maxZoom: 15,
      duration: 1000,
    });
  };

  useEffect(() => {
    initializeMap();

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    addMarkers();
  }, [userLocation, foundLocations, activeLocation]);

  useEffect(() => {
    if (
      currentPopupRef.current &&
      !showPopUp &&
      currentPopupRef?.current?.getLngLat().lat !== activeLocation?.map.lat &&
      currentPopupRef?.current?.getLngLat().lng !== activeLocation?.map.lon
    ) {
      currentPopupRef.current.remove();
    }

    if (activeLocation && showPopUp) {
      const popupContent = ReactDOMServer.renderToString(
        renderInfoCard === 'MapCard' ? (
          <NewMapCard location={activeLocation} size={size} />
        ) : (
          <InfoCard
            fields={activeLocation}
            slug={activeLocation.slug}
            current={activeLocation.current}
          />
        )
      );
      const newPopup = new Popup({ offset: [-20, -20], closeButton: false, anchor: 'top' })
        .setLngLat([activeLocation.map.lon, activeLocation.map.lat])
        .setHTML(popupContent)
        .addTo(mapRef.current!);

      const popupElement = newPopup.getElement();
      if (popupElement) {
        popupElement.style.zIndex = '22'; // Set z-index above 20 (you can adjust the value as needed)
      }

      // Update the current popup reference
      currentPopupRef.current = newPopup;
    }
  }, [activeLocation, showPopUp]);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
}
