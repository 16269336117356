import { ReactElement, useState, useEffect } from 'react';
import { Location } from 'pages/locations';
import { formatPhoneNumber } from 'lib/util';
import { IPageLocationFields } from 'types/contentful';

const DIRECTIONS_URL = 'https://www.google.com/maps/dir/?api=1&destination=';

type LocationDetailsCardProps = {
  location: Location;
  className?: string;
  size?: string;
  showDirections?: boolean;
};

const buildDirectionsUrl = (loc: IPageLocationFields): string =>
  encodeURI(`${DIRECTIONS_URL}${loc.address1} ${loc.city},${loc.state} ${loc.zipCode}`);

export default function NewLocationDetailsCard({
  location,
  className = '',
  size = 'lg',
  showDirections = true,
}: LocationDetailsCardProps): ReactElement {
  const [directionsUrl, setDirectionsUrl] = useState<string>('');

  useEffect(() => {
    if (location) {
      const url = buildDirectionsUrl(location);
      setDirectionsUrl(url);
    }
  }, []);

  return (
    <div className={`flex flex-col justify-between w-full border-transparent ${className}`}>
      <div>
        <h3 className={`${size == 'lg' ? 'text-2xl' : 'text-lg'} bold leading-none mb-1`}>
          {location.name}
        </h3>
      </div>
      <span className={`block ${size == 'lg' ? 'text-lg' : 'text-sm'}`}>
        {location?.address1 + ' '}
        {location?.address2 && <>{location.address2 + ' '}</>}
        {location?.address3 && <>{location.address3 + ' '}</>}
      </span>
      <span className={`block ${size == 'lg' ? 'text-lg' : 'text-sm'}`}>
        {location?.city + ', ' + location?.state + ' ' + location?.zipCode}
      </span>
      {showDirections ? (
        <span className={`block ${size == 'lg' ? 'text-lg' : 'text-sm'}`}>
          <a href={directionsUrl} tabIndex={-1}>
            <span className="text-tertiary-actual bold focus:outline-none">Directions</span>
            {location?.currentDistance ? ` (${location.currentDistance.toPrecision(3)} miles)` : ''}
          </a>
        </span>
      ) : null}
      {location?.phoneNumber ? (
        <div>
          <a
            className={`inline-block ${size == 'lg' ? 'text-lg' : 'text-sm'} underline`}
            href={`tel:${location?.phoneNumber}`}
          >
            {formatPhoneNumber(location.phoneNumber)}
          </a>
        </div>
      ) : null}
    </div>
  );
}
