import { ReactElement, useEffect, useState } from 'react';
import { IFragmentCtaFields, IFragmentInsurance } from 'types/contentful';
import CTA from 'components/UI/Cta';

interface InsuranceProps {
  heading: string;
  insurances: IFragmentInsurance[];
  disclaimer?: string | undefined;
  cta: IFragmentCtaFields | undefined;
}
export default function NewInsuranceProviders({
  heading,
  insurances,
  disclaimer,
  cta,
}: InsuranceProps): ReactElement {
  const columnsConfig = {
    sm: 2,
    md: 3,
    lg: 4,
  };

  const [columns, setColumns] = useState(columnsConfig.sm);

  useEffect(() => {
    const updateScreenSize = (): void => {
      const width = window.innerWidth;

      if (width >= 1024) {
        setColumns(columnsConfig.lg);
      } else if (width >= 768) {
        setColumns(columnsConfig.md);
      } else {
        setColumns(columnsConfig.sm);
      }
    };

    // Set initial state on mount
    updateScreenSize();

    // Add resize event listener
    window.addEventListener('resize', updateScreenSize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  const rows = Math.ceil(insurances.length / columns);

  return (
    <div className="my-10 bg-muted">
      <div className="w-3/4 mx-auto py-10 text-primary-actual">
        <h5 className="text-center font-light text-2xl lg:text-4xl">{heading}</h5>
        <ul
          className="mt-6 grid gap-1 ml-4 text-base list-none"
          style={{
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
          }}
        >
          {insurances
            .sort((a, b) => a.fields.insurer.localeCompare(b.fields.insurer))
            .map((insurance, i) => (
              <li
                key={i}
                className="truncate"
                style={{
                  gridColumn: (Math.floor(i / rows) % columns) + 1,
                  gridRow: (i % rows) + 1,
                }}
              >
                {insurance.fields.insurer}
              </li>
            ))}
        </ul>

        {disclaimer && (
          <div className="mt-3 italic px-6 py-3 text-md md:mt-10 mb-4 md:mb-8">{disclaimer}</div>
        )}

        {cta ? (
          <div className="flex justify-center mx-auto w-auto">
            <CTA {...cta} classNames="bg-white button-text text-primary-actual" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
