import { ReactElement } from 'react';
import Carousel from 'components/UI/Carousel';
import { IPageDoctorFields } from 'types/contentful';
import Link from 'components/UI/Link';
import { doctorDisplayName } from 'lib/util';

interface DoctorCarouselProps {
  doctors: IPageDoctorFields[];
  title: string;
  officeSlug?: string;
  titleElement?: ReactElement;
}

export default function DoctorCarousel({
  title,
  doctors,
  officeSlug,
  titleElement,
}: DoctorCarouselProps): ReactElement {
  const aspectRatio = 'portrait';

  const carouselItems = doctors?.map((doctor, index) => {
    return (
      <div className="text-center" key={index}>
        {doctor.doctorPhoto && (
          <div
            style={{ backgroundImage: `url('${doctor.doctorPhoto.fields.file.url}')`, aspectRatio }}
            className={`aspect-ratio-${aspectRatio} bg-cover bg-center mb-4`}
          ></div>
        )}

        <div>{doctorDisplayName(doctor)}</div>

        {doctor.slug && (
          <Link slug={`/doctors/${doctor.slug}`} query={{ office: officeSlug || '' }}>
            <a className="text-tertiary-actual">Learn More</a>
          </Link>
        )}
      </div>
    );
  });

  return <Carousel title={title} items={carouselItems ?? []} titleElement={titleElement} />;
}
