import { ReactElement } from 'react';
import { IComponentLocationServiceOfferingsFields } from 'types/contentful';
import Link from './Link';

export default function LocationOffering({
  services,
  secondRowServices,
  heading,
  secondRowHeading,
}: IComponentLocationServiceOfferingsFields): ReactElement {
  return (
    <div className="py-10 px-4 lg:px-16 text-primary-actual">
      {services.length > 0 ? (
        <div>
          <h5 className="font-light text-center text-2xl md:text-4xl">{heading}</h5>
          {services.length === 1 ? (
            <div className="flex justify-center text-lg text-center text-tertiary-actual mt-4 md:mt-2 ">
              {services[0].fields.slug ? (
                <Link slug={services[0].fields.slug}>
                  <a>{services[0].fields.service}</a>
                </Link>
              ) : (
                <p className="text-primary-actual">{services[0].fields.service}</p>
              )}
            </div>
          ) : null}

          {services.length >= 2 ? (
            <ul
              className="md:px-6 lg:px-24 mt-4 md:mt-2 grid gap-1 ml-4 text-lg text-center list-none text-tertiary-actual"
              style={{
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              }}
            >
              {services
                // .slice(0, 2)
                .sort((a, b) => a.fields.service.localeCompare(b.fields.service))
                .map((service, i) => (
                  <li key={i}>
                    {service.fields.slug ? (
                      <Link key={i + service.fields.service} slug={service.fields.slug}>
                        <a>{service.fields.service}</a>
                      </Link>
                    ) : (
                      <p className="text-primary-actual">{service.fields.service}</p>
                    )}
                  </li>
                ))}
            </ul>
          ) : null}
        </div>
      ) : null}
      {secondRowServices ? (
        <div className="mt-8">
          <h5 className="text-center text-2xl md:text-4xl">{secondRowHeading}</h5>
          <ul className="grid gap-1 ml-4 mt-4 md:mt-2 text-lg text-center list-none text-tertiary-actual grid-cols-1 md:grid-cols-3">
            {secondRowServices && secondRowServices.length
              ? secondRowServices
                  .slice(0, 14)
                  .sort((a, b) => a.fields.service.localeCompare(b.fields.service))
                  .map((service, i) => {
                    return (
                      <li key={i} className="overflow-hidden whitespace-nowrap text-ellipsis">
                        {service.fields.slug ? (
                          <Link key={i + service.fields.service} slug={service.fields.slug}>
                            <a className="truncate block">{service.fields.service}</a>
                          </Link>
                        ) : (
                          <p className="text-primary-actual truncate">{service.fields.service}</p>
                        )}
                      </li>
                    );
                  })
              : null}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
