import { ReactElement } from 'react';
import Carousel from 'components/UI/Carousel';
import { IFragmentCtaFields } from 'types/contentful';
import { Document } from '@contentful/rich-text-types';
import { ProductVariantFieldsWithBrand } from 'components';

interface CarouselProps {
  products: ProductVariantFieldsWithBrand[];
  title: string;
  description?: Document | undefined;
  cta?: IFragmentCtaFields | undefined;
}

export default function ProductCarousel({
  title,
  description,
  products,
  cta,
}: CarouselProps): ReactElement {
  const aspectRatio = 'landscape';

  const carouselItems = products?.map((item, index) => {
    return item.primaryFrameImage ? (
      <a href={item.url}>
        <div className="text-center" key={index}>
          <div
            style={{
              backgroundImage: `url('${item.primaryFrameImage.fields.file.url}')`,
              aspectRatio,
            }}
            className={`aspect-ratio-${aspectRatio} bg-cover bg-center`}
          ></div>
          <span className="text-lg">{item.brand ?? item.sku}</span>
        </div>
      </a>
    ) : (
      <></>
    );
  });

  return (
    <Carousel
      title={title}
      description={description}
      items={carouselItems ?? []}
      cta={cta}
      style="frames"
    />
  );
}
