import { ReactElement } from 'react';
import Carousel from 'components/UI/Carousel';
import {
  IComponentArticleCarouselFields,
  IComponentHeroInlineCircle,
  ITemplateCareersPageFields,
  ITemplateContactUsFields,
  ITemplateEcpAboutUsFields,
  ITemplateEcpAcquisitionPageFields,
  ITemplateEcpCaresPageFields,
  ITemplateEcpHomePageFields,
  ITemplateEcpOurPracticesPageFields,
  ITemplateEcpProspectPageFields,
  ITemplateFreeformPageFields,
  ITemplateHomePageFields,
  ITemplateHubSiteCareersPageFields,
  ITemplateHubSiteHomePageFields,
  ITemplateHubSiteIndividualServicesFields,
  ITemplateHubSiteOurServicesFields,
  ITemplateHubSitePromosPageFields,
} from 'types/contentful';
import { Entry } from 'contentful';

// Define a more explicit ContentEntry type to handle `fields`
type ContentEntry = Entry<{
  image?: {
    fields: {
      file: {
        url: string;
      };
    };
  };
}>;

type TemplateFields = Partial<
  | ITemplateEcpAboutUsFields
  | ITemplateEcpAcquisitionPageFields
  | ITemplateCareersPageFields
  | ITemplateEcpCaresPageFields
  | ITemplateContactUsFields
  | ITemplateEcpHomePageFields
  | ITemplateEcpOurPracticesPageFields
  | ITemplateEcpProspectPageFields
  | ITemplateFreeformPageFields
  | ITemplateHomePageFields
  | ITemplateHubSiteCareersPageFields
  | ITemplateHubSiteHomePageFields
  | ITemplateHubSiteIndividualServicesFields
  | ITemplateHubSiteOurServicesFields
  | ITemplateHubSitePromosPageFields
>;

export default function ArticleCarousel({
  heading,
  blocks,
}: IComponentArticleCarouselFields): ReactElement {
  const aspectRatio = 'landscape';

  // `hasHero` is used to check if `hero` exists, and optionally `content`
  const hasHero = (
    fields: TemplateFields
  ): fields is { hero: IComponentHeroInlineCircle[]; content?: ContentEntry[] } => {
    if ('hero' in fields && Array.isArray(fields.hero)) {
      return true;
    }

    if ('content' in fields && fields.content) {
      return fields.content.some((entry) => entry?.fields?.image);
    }

    return false;
  };

  const carouselItems = blocks.map(({ fields: { slug, title, template } }, index) => {
    let image = '/images/preview-placeholder.jpeg';

    if (template?.fields && hasHero(template.fields)) {
      image =
        template.fields.hero?.[0]?.fields.image?.fields.file?.url ||
        (Array.isArray(template.fields.content) &&
          template.fields.content[0]?.fields?.image?.fields.file?.url) ||
        '/images/preview-placeholder.jpeg';
    }

    return (
      <div className="flex flex-col space-between justify-end text-center" key={index}>
        {image && (
          <div
            style={{ backgroundImage: `url('${image}')`, aspectRatio }}
            className={`aspect-ratio-${aspectRatio} bg-cover bg-center mb-4`}
          ></div>
        )}
        <div>
          {title && <div className="mb-2 px-4">{title}</div>}
          <a href={`/${slug}`} className="text-tertiary-actual">
            Learn More
          </a>
        </div>
      </div>
    );
  });

  return <Carousel title={heading} items={carouselItems} />;
}
