import { ReactElement } from 'react';
import Link from 'components/UI/Link';
import { Location } from 'pages/locations';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';
import NewLocationDetailsCard from './NewLocationDetailsCard';

type MapCardProps = {
  location: Location;
  className?: string;
  size?: string;
};

export default function NewMapCard({
  location,
  className,
  size = 'lg',
}: MapCardProps): ReactElement {
  return (
    <div className="p-3">
      <NewLocationDetailsCard
        location={location}
        size={size}
        className={`${size === 'lg' ? 'space-y-2' : null} ${className}`}
      />
      <div className="flex flex-col text-center w-full">
        <ScheduleAppointmentCta
          location={location}
          colorScheme="Primary"
          wrapperClasses=""
          classNames={`${size === 'lg' ? 'button-text' : 'text-sm'} mt-6 px-2 remove-cta-padding `}
        />
        <Link slug={`locations/${location?.slug}`}>
          <a
            className={`block text-tertiary-actual ${
              size === 'lg' ? 'button-text' : 'text-sm'
            } mt-3`}
          >
            View Location Details
          </a>
        </Link>
      </div>
    </div>
  );
}
